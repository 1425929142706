<template>
  <f7-popup class="filterselect-popup" :opened="open" :tablet-fullscreen="false" :backdrop="true" @popup:opened="openPopup" @popup:closed="closePopup">
    <f7-page>
      <f7-navbar>
        <f7-nav-title>{{ $t.getTranslation("LBL_SORT_PRODUCTS") }}</f7-nav-title>
        <f7-nav-right>
          <f7-link popup-close>
            <font-awesome-icon :icon="['far', 'times']" fixed-width />
          </f7-link>
        </f7-nav-right>
      </f7-navbar>
      <div class="filter-container">
        <div class="title">
          <h1>{{ $t.getTranslation("LBL_SORT_BY") }}</h1>
        </div>
        <f7-list no-hairlines>
          <f7-list-input
            v-model:value="formData.SortValue"
            name="Sort"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="select"
            validate
          >
            <template v-if="formData.Sort.length > 0">
              <option v-for="sort in formData.Sort" :key="'sort_' + sort.value" :value="sort.value">{{ sort.name }}</option>
            </template>
          </f7-list-input>
        </f7-list>
      </div>
      <div class="shipping-btn">
        <f7-button class="apply-button" fill large @click="applyFilter()">{{ $t.getTranslation("LBL_APPLY_SORT") }}</f7-button>
        <!-- <f7-button class="reset-button" large @click="resetFilter">{{ $t.getTranslation("LBL_RESET") }}</f7-button> -->
      </div>
    </f7-page>
  </f7-popup>
</template>

<script>
import { reactive, computed, onMounted, ref, inject } from "vue";
import { f7 } from "framework7-vue";
import { get } from "@/utils/axios";
import { useStore } from "@/store";
import { configs } from "@/utils/configs";

export default {
  name: "ProductSortPopup",
  components: {},
  props: {
    open: Boolean,
  },
  emits: ["opened", "closed", "selected", "cleared"],
  setup(props, { emit }) {
    const store = useStore();

    const $t = inject("$translation");

    const initialState = {
      Sort: [
        { value: 0, field: "", sort: null, name: $t.getTranslation("LBL_ALL") },
        { value: 3, field: "AttributeList.Name", sort: "ASC", name: $t.getTranslation("LBL_NAME_ASCENDING") },
        { value: 4, field: "AttributeList.Name", sort: "DESC", name: $t.getTranslation("LBL_NAME_DESCENDING") },
      ],
      SortValue: 0,
    };

    if (!configs?.hideProductPrice) {
      initialState.Sort.push({ value: 1, field: "ProductPrice", sort: "DESC", name: $t.getTranslation("LBL_PRICE_HIGH_TO_LOW") });
      initialState.Sort.push({ value: 2, field: "ProductPrice", sort: "ASC", name: $t.getTranslation("LBL_PRICE_LOW_TO_HIGH") });
    }

    const formData = reactive({ ...initialState });

    let page = 1;
    let lastPage = 1;

    const openPopup = () => {
      emit("opened", true);
    };

    const closePopup = () => {
      emit("closed", true);
    };

    const isAlreadyCheck = computed(() => {
      let counter = 0;
      for (var k in formData.checks) {
        if (formData.checks) {
          counter += 1;
        }
      }

      return t;
    });

    const applyFilter = () => {
      f7.popup.close();
      let sortvalue = [];

      for (var k in formData.checks) {
        if (formData.checks[k]) {
          SearchValue.push(k);
        }
      }

      if (formData["SortValue"] != "0") {
        let filter = formData.Sort.filter(function (f) {
          if (f.value == formData.SortValue) {
            return f;
          }
        });
        sortvalue = [];
        sortvalue.push({ SortingField: filter[0].field, SortingValue: filter[0].sort });
      } else {
        sortvalue = [];
        sortvalue.push({ SortingField: "", SortingValue: "" });
      }

      if (sortvalue.length > 0) {
        emit("selected", {
          SortingField: sortvalue[0].SortingField,
          SortingValue: sortvalue[0].SortingValue,
        });
      }
    };

    const resetFilter = (data) => {
      Object.assign(formData, initialState);
      emit("cleared");
    };

    onMounted(() => {});

    return {
      formData,
      openPopup,
      closePopup,
      applyFilter,
      resetFilter,
      store,
    };
  },
};
</script>

<style scoped>
.filterselect-popup.popup {
  --f7-popup-tablet-height: 270px;
}
.filterselect-popup.popup .navbar .title {
  margin-left: 15px;
}
.filterselect-popup .filter-container {
  width: calc(100% - 60px);
  margin: 20px auto;
}
/* .filterselect-popup .apply-button {
  margin: 10px auto 0px;
} */

.filter-container .title {
  margin-bottom: 10px;
}
.filter-container .list {
  width: 100%;
  --f7-list-item-padding-vertical: 0px;
  --f7-list-item-padding-horizontal: 0px;
}
.shipping-btn {
  padding: 5px;
  width: calc(100% - 60px);
  margin: 20px auto;
}
</style>
